import React from 'react';
import styled from 'styled-components'
import BannerImg1 from "../images/landing/Banner2.jpg"
import BannerImg2 from "../images/landing/Banner3.jpg"
import BannerImg3 from "../images/landing/Banner4.jpg"
import BannerImg4 from "../images/landing/Banner6.jpg"

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BannerSliderContainer= styled.div`
    width:100%;
    height:768px;
    position:relative;    
    @media screen and (max-width: 991px) {
        height:100%;
    }
`;
const BannerSlide = styled.img`
    width:100%;
    height:768px;
    margin-bottom:0px;
    outline:none;
    @media screen and (max-width: 991px) {
        height:100%;
    }
    @media screen and (max-width: 767px) {
        height:100%;
    }
    @media screen and (max-width: 479px) {
        height:368px;
    }
    object-fit:cover;
    object-position:${props => props.objectPosition?props.objectPosition :'50% 50%'};
    transition :all 0.8s ease-in-out;
`;
const ImageWrap=styled.div`
    width:100%;
    height:100%;
    overflow:hidden;
`;
const BannerSliderOverlay=styled.div`
    position:absolute;
    width:100%;
    height:100%;
    z-index:1;
    background:transparent;
    top: 0;
    left: 0;
`;

const HomeBannerSlider = (props) => {
    var settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:false,
        autoplay: true,
        autoplaySpeed: 5000,
        fade:true,
        centerMode: true,
        centerPadding: "0px",
      };
    return (
        <BannerSliderContainer id="banner-slider">
            {/* <BannerImg src={BannerImg1}></BannerImg> */}
            {props.home?
            <Slider {...settings} >             
                <ImageWrap><BannerSlide src={BannerImg1}></BannerSlide></ImageWrap>
                <ImageWrap><BannerSlide src={BannerImg2}></BannerSlide></ImageWrap>
                <ImageWrap><BannerSlide src={BannerImg4}></BannerSlide></ImageWrap>                    
                <ImageWrap><BannerSlide src={BannerImg3}></BannerSlide></ImageWrap>                                   
            </Slider>
                :
            <Slider {...settings} >             
                <ImageWrap><BannerSlide src={"../images/"+props.image} objectPosition={props.objectPosition}></BannerSlide></ImageWrap>                  
            </Slider>
            }
            <BannerSliderOverlay></BannerSliderOverlay>
        </BannerSliderContainer>
    );
};

export default HomeBannerSlider;